import * as React from "react";
import type { HeadFC } from "gatsby";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
      <>
        <main>
          <div className="section">
            <StaticImage src="../images/hvac_system.jpg" alt="HVAC system" className="section__background" />
            <div className="section__content">
              <Header />
              <div className="pure-g discovery">
                <div className="pure-u-1 pure-u-md-3-4 pure-u-lg-1-2">
                  <h1 className="discovery__title">HVAC problem solvers</h1>
                  <div className="discovery__description">
                    <p>Our expert technicians are very capable of <b>installing</b>, <b>maintaining</b> and <b>repairing</b> a multitude of HVAC systems.</p>
                    <p>We work diligently to provide speedy services while maintaining high standards and reasonable rates.</p>
                  </div>
                </div>
                <div className="pure-u-1 pure-u-md-1-4 pure-u-lg-1-2" />
              </div>
            </div>
          </div>
          <div className="section">
            <StaticImage src="../images/hvac_toolbox.jpg" alt="HVAC toolbox" className="section__background" />
            <div className="section__content">
              <div className="pure-g discovery">
                <div className="pure-u-1 pure-u-md-3-4 pure-u-lg-1-2">
                  <h1 className="discovery__title"><a id="contact-us">Contact Us</a></h1>
                  <div className="discovery__description">
                    <h3>Sunrise HVAC</h3>
                    <ul>
                      <li>CCB#239392</li>
                      <li>Address: 203 NE 134th Pl, Portland, OR 97230.</li>
                      <li>Phone: 971-998-9117 - 503-544-4969.</li>
                      <li>Email: <a href="mailto:sunriseheating22@gmail.com">sunriseheating22@gmail.com</a></li>
                      <li>Service hours: Monday - Saturday, 8AM - 6PM.</li>
                      <li>Service area: Oregon Area.</li>
                    </ul>
                  </div>
                </div>
                <div className="pure-u-1 pure-u-md-1-4 pure-u-lg-1-2" />
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Sunrise HVAC</title>
